











import Vue from 'vue'
import NavBar from '@/components/NavBar.vue'
import HomeHero from '@/components/HomeHero.vue'
import AboutMe from '@/components/AboutMe.vue'
import Skills from '@/components/Skills.vue'
import ProjectsAndExperiences from '@/components/ProjectsAndExperiences.vue'
import Contact from '@/components/Contact.vue'

export default Vue.extend({
  name: 'App',
  components: {
    HomeHero,
    NavBar,
    AboutMe,
    Skills,
    ProjectsAndExperiences,
    Contact
  }
})
