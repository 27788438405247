







































































































































import Vue from 'vue'
export default Vue.extend({
  data: () => ({
    showNav: false,
    showResumeBtn: true
  }),
  mounted () {
    const aboutElement = document.getElementById('about') as HTMLDivElement
    const skillsElement = document.getElementById('skills') as HTMLDivElement
    const projectsElement = document.getElementById('projects') as HTMLDivElement
    const contactElement = document.getElementById('contact') as HTMLDivElement
    const aboutLi = this.$refs.about as HTMLDivElement
    const skillsLi = this.$refs.skills as HTMLDivElement
    const projectsLi = this.$refs.projects as HTMLDivElement
    const contactLi = this.$refs.contact as HTMLDivElement
    const reset = () => {
      contactLi.classList.remove('active')
      projectsLi.classList.remove('active')
      skillsLi.classList.remove('active')
      aboutLi.classList.remove('active')
    }
    const offsetHeightContactElement = window.innerHeight > 800 ? 1200 : 600
    window.addEventListener('scroll', () => {
      if (window.scrollY >= contactElement.offsetTop - offsetHeightContactElement) {
        if (!contactLi.classList.contains('active')) {
          reset()
          contactLi.classList.add('active')
        }
      } else if (window.scrollY >= projectsElement.offsetTop - 100) {
        if (!projectsLi.classList.contains('active')) {
          reset()
          projectsLi.classList.add('active')
        }
      } else if (window.scrollY >= skillsElement.offsetTop - 100) {
        if (!skillsLi.classList.contains('active')) {
          reset()
          skillsLi.classList.add('active')
        }
      } else if (window.scrollY >= aboutElement.offsetTop - 100) {
        if (!aboutLi.classList.contains('active')) {
          reset()
          aboutLi.classList.add('active')
          if (window.innerWidth < 500) {
            this.showResumeBtn = false
          }
        }
      } else if (window.scrollY < aboutElement.offsetTop) {
        reset()
        if (window.innerWidth < 500) {
          this.showResumeBtn = true
        }
      }
    })
    setTimeout(() => {
      this.showNav = true
    }, 1000)
  }
})
