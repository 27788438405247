
































































































import Vue from 'vue'
export default Vue.extend({
  data: () => ({
    showAbout: false
  }),
  mounted () {
    window.addEventListener('scroll', () => {
      var elementTarget = this.$refs.about as HTMLDivElement
      if (window.scrollY >= elementTarget.offsetTop - 100) {
        this.showAbout = true
      }
    })
  }
})
