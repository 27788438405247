import Vue from 'vue'
import App from './App.vue'
import VueSmoothScroll from 'vue2-smooth-scroll'
import 'animate.css'

Vue.config.productionTip = false

Vue.use(VueSmoothScroll, {
  duration: 300,
  updateHistory: false
})

new Vue({
  render: h => h(App)
}).$mount('#app')
