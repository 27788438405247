



















































































import Vue from 'vue'
export default Vue.extend({
  data: () => ({
    showTextHero: false,
    showArrow: false
  }),
  mounted () {
    setTimeout(() => {
      this.showTextHero = true
    }, 250)
    setTimeout(() => {
      this.showArrow = true
    }, 1000)
  }
})
