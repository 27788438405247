













































































































































































































































































import Vue from 'vue'
import Projects from '@/assets/projects.json'

interface Project {
  title: string;
  image: string;
  typeImg: string;
  type: string;
  year: number;
  tool: string;
  bgCalColor?: string;
  showScroll?: boolean;
}

export default Vue.extend({
  data: () => ({
    items: [] as Project[],
    showHeaderText: false,
    imageUrl: ''
  }),
  async mounted () {
    const newItems = await Promise.all(Projects.map(async (i: Project) => {
      if (i.image) {
        const result = await this.getAverageRGB(this.image(i.image))
        i.bgCalColor = result
        i.showScroll = false
      }
      return i
    }))
    this.items = newItems.sort((a, b) => b.year - a.year)
    // const divs = Array.from(elementTargets.children) as HTMLDivElement[]
    const elementTarget = this.$refs.projectsAndExperiences as HTMLDivElement
    window.addEventListener('scroll', () => {
      if (window.scrollY >= elementTarget.offsetTop - 150) {
        this.showHeaderText = true
        const posts = this.$refs.posts as HTMLDivElement[]
        for (const [index, item] of posts.entries()) {
          if (window.scrollY >= item.offsetTop - 450) {
            // console.log(item.offsetTop)
            this.items[index].showScroll = true
          }
        }
      }
    })
  },
  methods: {
    image (image: string) {
      return require(`../assets/projects/${image}`)
    },
    showImageFullScreen (e: Event) {
      console.log((e.target as HTMLImageElement).src)
      this.imageUrl = (e.target as HTMLImageElement).src
    },
    closeImageFullScreen () {
      this.imageUrl = ''
    },
    async getAverageRGB (imageUrl: string) {
      const blockSize = 5 // only visit every 5 pixels
      const imgEl = document.createElement('img')
      imgEl.src = imageUrl
      return new Promise<string>((resolve) => {
        imgEl.onload = () => {
          const canvas = document.createElement('canvas')
          const context = canvas.getContext && canvas.getContext('2d')
          let data
          let i = -4
          const rgb = {
            r: 255,
            g: 255,
            b: 255
          }
          let count = 0
          if (!context) {
            resolve(`rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`)
          }
          const height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height
          const width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width
          try {
            if (!context) {
              throw String('error')
            }
            context.drawImage(imgEl, 0, 0)
            data = context.getImageData(0, 0, width, height)
            const length = data.data.length
            while ((i += blockSize * 4) < length) {
              ++count
              rgb.r += data.data[i]
              rgb.g += data.data[i + 1]
              rgb.b += data.data[i + 2]
            }
            // ~~ used to floor values
            rgb.r = ~~(rgb.r / count)
            rgb.g = ~~(rgb.g / count)
            rgb.b = ~~(rgb.b / count)
            // console.log(`rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`)
            resolve(`rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`)
          } catch (e) {
            // /* security error, img on diff domain */alert('x');
            resolve(`rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`)
          }
        }
      })
    }
  }
})
