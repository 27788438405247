


































































































































import Vue from 'vue'
export default Vue.extend({
  data: () => ({
    showHeaderText: false,
    showSkillList: false
  }),
  mounted () {
    window.addEventListener('scroll', () => {
      var elementTarget = this.$refs.headerText as HTMLDivElement
      if (window.scrollY >= elementTarget.offsetTop - 150) {
        this.showHeaderText = true
        this.showSkillList = true
      }
    })
  }
})
